import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./apartmentdetails.css";
import Carousel from "react-bootstrap/Carousel";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import { Route, withRouter } from "react-dom";
import parser from "html-react-parser";
import SendQuotationModal from "../SendQuotationModal/SendQuotationModal"
import OwlCarousel from "react-owl-carousel"
import {Modal} from "antd"
class SingleHomeapartmentdetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalShow: false,
      email: "",
      property_type: null, // furnished, standard, superior
      price_type: null, // non-refundable (hot-deal), cancellable ()
      popupfor: '',

    }

  }
  componentDidMount() {
    window.scrollTo(0, 0);
    // axios.get(`/be/products/getAll.json`).then(res => {
    //     console.log('result', res.data)
    // })
  }


  showModal = (name) => {
    this.setState({
      visible: true,
      popupfor: name
    });
  };

  render() {
    const renderHTML = (escapedHTML) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: escapedHTML },
      });


    return (
      <div className="row apartmentdetails mb-30">
        <div className="col-md-12">
          <p className="fw-700 mb-30">{this.props.apartmenttypetitle}</p>
          <div className="row icon-list mb-30">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3 col-6">
              <span>
                <img
                    className=""
                    alt="Guest"
                    src={require("../../assets/apartmentdetails/Guests.png")}
                />
              </span>
                  <p>
                    <span>{this.props.noguest}</span> Guest
                  </p>
                </div>
                <div className="col-md-3 col-6">
              <span>
                <img
                    className=""
                    alt={this.props.apartmenttype}
                    src={require("../../assets/apartmentdetails/Studio.png")}
                />
              </span>
                  <p>{this.props.apartmenttype}</p>
                </div>
                <div className="col-md-3 col-6">
              <span>
                <img
                    className=""
                    alt="Bed"
                    src={require("../../assets/apartmentdetails/Bed.png")}
                />
              </span>
                  <p>
                    <span>{this.props.nobeds}</span> Bed
                  </p>
                </div>
                <div className="col-md-3 col-6">
              <span>
                <img
                    className=""
                    alt="Bathroom"
                    src={require("../../assets/apartmentdetails/Bathroom.png")}
                />
              </span>
                  <p>
                    <span>{this.props.nobath}</span> Bathroom
                  </p>
                </div>
              </div>
            </div>

          </div>

          <span />
        </div>
        {/*<div className="row apt-det-img-gal mb-20">
          <div className="col-md-10">

            <OwlCarousel
                className="owl-theme deskview"
                // loop
                margin={10}
                items="4"
                dots={false}
                nav
            >
              {this.props.images.map((images, index) => (
                  <div className="item" onClick={() => this.showModal(this.props.apartmenttypetitle)}>
                    <img className="" src={images.image} />

                  </div>))}
            </OwlCarousel>
            <OwlCarousel
                className="owl-theme mobview"
                // loop
                margin={10}
                items="1"
                dots={false}
                nav

            >
              {this.props.images.map((images, index) => (
                  <div className="item" onClick={() => this.showModal(this.props.apartmenttypetitle)}>
                    <img className="" src={images.image} />

                  </div>))}
            </OwlCarousel>



            {this.state.popupfor == this.props.apartmenttypetitle &&

            <Modal
                width={700}
                visible={this.state.visible}

                onCancel={this.handleCancel}
            >
              <OwlCarousel
                  className="owl-theme"
                  // loop
                  margin={10}
                  items="1"
                  dots={true}
                  nav
              >

                {this.props.images.map((images, index) => (
                    <div className="item">
                      <img className="" src={images.image} />
                    </div>))}


              </OwlCarousel>
            </Modal>
            }

          </div>



        </div>*/}
        {/*<div className="fl-100 apartment-category-card product">*/}


        {/*</div>*/}

        <div className="col-md-12">
          <div className="fl-100 apart-cat-desc mb-20">
            {/*<p className="apart-cat-title text-center fw-700">
              {this.props.override_apart_cat_title}
              <span className="apart-cat-title text-center fw-700 statititle">
              {this.props.static_title}
            </span>
            </p>*/}

            {/* <ul className="apartment-amenities">
                        {this.props.amenities.map((amenity, index) => (
                          <li key={index}><span className="apartment-amenities-icon"><img className="" src={amenity.image}/></span><span className="font-12">{amenity.name}</span>
                                 <span className="p2">{amenity.description}</span>
                          </li>
                        ))}

                    </ul> */}

            {this.props.amenities.map((amenity, index) => (
                <p key={index} className="det-amenities font-cap mb-10">
              <span className="det-amenities-img">
                <img className="" alt={amenity.name} src={amenity.image}/>
              </span>
                  <span className="font-12 fw-600">{amenity.name}</span>
                  <br/>
                  <span className="font-12">{amenity.description}</span>
                </p>
            ))}
          </div>
          <p className="p2">{renderHTML(this.props.apartmenttypedesc)}</p>
        </div>
        {/*<div className="col-md-3">
          <div className="fl-100 text-center heg-100">
            <p className="p2 apartmentdetails-price">
              {this.props.apartmenttupeprice != "Sold out" && "starting from"}
            </p>
            <h3 className="font-green mb-10">
              {this.props.queryParam.startDate != "" &&
              this.props.apartmenttupeprice ? (
                <React.Fragment>&#8377;</React.Fragment>
              ) : (
                ""
              )}
              {this.props.queryParam.startDate == ""
                ? "Enter Date"
                : this.props.apartmenttupeprice}
              {}
            </h3>
            {this.props.cutprice !== 0 &&
              this.props.cutprice != this.props.apartmenttupeprice && (
                <h3 className="striked-start-price">
                  {this.props.cutprice && (
                    <strike> &#8377;{this.props.cutprice}</strike>
                  )}
                </h3>
              )}
            <button className="listing-btn">View Details</button>
            <div className='col-md-12'>
            	{this.props.startDate instanceof Date && this.props.endDate instanceof Date && this.props.cutprice && (
            		<p className='text-center email-link'><a href="#" onClick={this.openModal}><i className='far fa-envelope'></i> Email
            			me the prices</a>
            		</p>
            	)}
              <SendQuotationModal show={this.state.modalShow} startdate={this.props.queryParam.startDate} enddate={this.props.queryParam.endDate} endDateObj={this.props.endDate}
                                  slug={this.props.slug} roomtype_id={this.props.roomType_id} title={this.props.apartmenttypetitle} startDateObj={this.props.startDate} no_days={this.props.no_days}
                                  onHide={this.hideModal} />

            </div>
          </div>
        </div>*/}
      </div>
    );
  }
}
export default SingleHomeapartmentdetails;
