import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./loginpage.css";
import history from "../../lib/history";
import axios from "../../lib/axios/axios";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import {
  Form,
  Input,
  TextArea,
  Button,
  Select,
  Checkbox,
} from "semantic-ui-react";
import { Alert } from "antd";
import swal from "sweetalert";

// import Form from "react-bootstrap/Form";

// import { Route, withRouter } from "react-dom";

class ResetPasswordPage extends Component {
  constructor(props, context) {
    super(props);
    let loggedIn = false;
    if (localStorage.getItem("login")) {
      loggedIn = true;
    }
    this.state = {
      loggedIn,
      passowod: "",
      confirmPassword: "",
      showSuccess: false,
      showError: false,
      msg: "",
    };
  }

  resetPassword = () => {
    const { password, confirmPassword } = this.state;
    if (password == "" && confirmPassword == "") return;
    if (password === confirmPassword) {
      axios({
        method: "POST",
        url:
          "https://www.oliveservicedapartments.com/olive_api/public/api/update_password",

        data: {
          password: password,
          id: this.props.match.params.id,
        },
      }).then((response) => {
        if (response && response.status == 400) {
          return;
        }
        if (response.status == 200) {
          swal(`Password changed successfully`, {
            icon: "success",
            buttons: {
              cancel: "Ok",
            },
          }).then(function () {
            window.location.href = "/olive/auth/login";
          });

          // this.setState({ showSuccess: true });
        }
        if (response.status == false) {
          this.setState({ showError: true, msg: response.message });
        }
      });
    } else {
      swal(`Password and confirm password must be samey`, {
        icon: "warning",
      });
    }
  };

  render() {
    if (this.state.loggedIn === true && this.props.lgnstatus === true) {
      history.push(
        `/${localStorage.getItem("cityName")}/${localStorage
          .getItem("apartmenttitle")
          .toLowerCase()}/checkout`
      );
    }

    if (this.state.loggedIn === true) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        {/* login */}
        {this.state.showSuccess ? (
          <Alert
            message="Password changed successfully"
            type="success"
            closable
            onClose={() => this.setState({ showSuccess: false })}
          />
        ) : null}
        {this.state.showError ? (
          <Alert
            message={this.state.msg}
            type="warning"
            closable
            onClose={() => this.setState({ showError: false, msg: "" })}
          />
        ) : null}

        <section className="pad-100 bg-grey login-section">
          <div className="container ">
            <div className="offset-md-3 col-md-6 col-12 login-container">
              <React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <p className="login-title">
                      Reset Password
                      <div className="hr-line-left-thick"></div>
                      {this.state.msgshow}
                    </p>
                  </div>
                </div>

                <Form className="registerform">
                  <div className="row ">
                    <div className="col-md-12">
                      <Form.Field>
                        <label>Password*</label>
                        <input
                          type="password"
                          placeholder="Password"
                          name="username"
                          onChange={(e) => {
                            this.setState({ password: e.target.value });
                          }}
                          required
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Confirm Password*</label>
                        <input
                          type="password"
                          placeholder="Confirm Password"
                          name="username"
                          onChange={(e) => {
                            this.setState({ confirmPassword: e.target.value });
                          }}
                          required
                        />
                      </Form.Field>
                    </div>

                    <div className="col-md-6">
                      <Button
                        type="button"
                        className="listing-btn mb-15"
                        onClick={() => this.resetPassword()}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            </div>
          </div>
        </section>

        {/* login end */}
      </React.Fragment>
    );
  }
}
export default ResetPasswordPage;
