import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./SendQuotationModal.css";
import axios from "../../lib/axios/axios";
import swal from "sweetalert";

class SendQuotationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      property_type: "",
      price_type: "",
      email: "",
      loading: false,
      phone_number: "",
      is_not_filled: false,
    };
  }

  getDBPriceType(selectedPriceType) {
    switch (selectedPriceType) {
      case "Furnished":
        if (this.state.price_type === "cancellable") {
          return "refundable_price";
        } else if (this.state.price_type === "non-refundable") {
          return "price";
        }
        break;
      case "Standard":
        if (this.state.price_type === "cancellable") {
          return "srefundable_price";
        } else if (this.state.price_type === "non-refundable") {
          return "snrefundable_price";
        }
        break;
      case "Superior":
        if (this.state.price_type === "cancellable") {
          return "prefundable_price";
        } else if (this.state.price_type === "non-refundable") {
          return "pnrefundable_price";
        }
        break;
    }
  }

  sendMail = () => {
    if (this.checkFilled()) {
      return;
    }
    let url = `https://www.oliveservicedapartments.com/olive_api/public/api/quotation`;
    let data = {
      slug: this.props.slug,
      check_in: this.props.startdate,
      check_out: this.props.enddate,
      email: this.state.email,
      roomtype_id: this.props.roomtype_id,
      property_type: this.state.property_type,
      price_type: this.getDBPriceType(this.state.property_type),
      phone_number: this.state.phone_number,
    };
    this.setState({
      loading: true,
    });
    axios
      .post(url, data)
      .then(() => {
        this.props.onHide();
        this.setState({
          loading: false,
        });
        swal(`Email sent successfully to ${this.state.email}.`, {
          icon: "success",
        });
      })
      .catch(() => {
        swal(
          "We couldn't send the email. Please try again. If error persists, please contact our team.",
          {
            icon: "error",
          }
        );
        this.setState({
          loading: false,
        });
      });
    // console.log(data)
    // console.log(this.props.startdate)
    // console.log(this.props.enddate)
    // console.log(this.props.roomtype_id)
  };
  handleEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
    e.preventDefault();
    e.stopPropagation();
  };
  handlePropertyType = (e) => {
    this.setState({
      property_type: e.currentTarget.value,
    });
    // e.preventDefault()
    e.stopPropagation();
  };
  handlePriceType = (e) => {
    this.setState({
      price_type: e.currentTarget.value,
    });
    // e.preventDefault()
    e.stopPropagation();
  };

  handlePhoneNumber = (e) => {
    this.setState({
      phone_number: e.currentTarget.value,
    });
    e.stopPropagation();
  };
  checkFilled = () => {
    if (
      this.state.property_type.trim().length > 0 &&
      this.state.price_type.trim().length > 0 &&
      this.state.email.trim().length > 0 &&
      this.state.phone_number.trim().length > 0
    ) {
      this.setState({
        is_not_filled: false,
      });
      return false;
    } else {
      this.setState({
        is_not_filled: true,
      });
      return true;
    }
  };

  componentDidMount() {
    if (process.env.REACT_APP_LISTINGS === "single") {
      this.setState({
        property_type: "Standard",
      });
    }
  }

  // canSendEmail() {
  // 	const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // 	if (this.state.property_type !== "" && this.state.price_type !== "" && this.state.email.match(mailformat))
  // 		return true
  // 	else
  // 	 return false
  // }
  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onClick={(e) => e.stopPropagation()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.startDateObj instanceof Date &&
              this.props.endDateObj instanceof Date && (
                <h5 className="header">
                  Email for Pricing Quote <br /> {this.props.title}
                  <br /> ({this.props.startDateObj.getDate()}/
                  {this.props.startDateObj.getMonth() + 1} -{" "}
                  {this.props.endDateObj.getDate()}/
                  {this.props.endDateObj.getMonth() + 1}) {this.props.no_days}{" "}
                  Nights
                </h5>
              )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.is_not_filled && (
            <>
              <div className="alert alert-danger">
                <strong>Error!</strong> Please fill all the details before
                submitting.
              </div>
            </>
          )}
          {/*<h5 className='header'>In order for us to mail you the prices, we need the following information first:</h5>*/}
          <form>
            {process.env.REACT_APP_LISTINGS !== "single" && (
              <div class="form-group">
                <p className="question">Room category: </p>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      onChange={this.handlePropertyType}
                      value="Furnished"
                      className="form-check-input"
                      name="property-type"
                    />{" "}
                    Standard
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      onChange={this.handlePropertyType}
                      value="Standard"
                      className="form-check-input"
                      name="property-type"
                    />{" "}
                    Superior
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      onChange={this.handlePropertyType}
                      value="Superior"
                      className="form-check-input"
                      name="property-type"
                    />{" "}
                    Premier
                  </label>
                </div>
              </div>
            )}
            <div className="form-group">
              <p className="question">Rate category:</p>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={this.handlePriceType}
                    value="non-refundable"
                    className="form-check-input"
                    name="price-type"
                  />{" "}
                  Hot Deal
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label">
                  <input
                    type="radio"
                    onChange={this.handlePriceType}
                    value="cancellable"
                    className="form-check-input"
                    name="price-type"
                  />{" "}
                  Best Available Rate
                </label>
              </div>
            </div>

            <div className="form-group">
              <p className="question">Your Phone: </p>
              <input
                type="number"
                onBlur={this.handlePhoneNumber}
                className="form-control"
                placeholder="919898989898"
                id="phone_number"
              />
            </div>
            <div className="form-group">
              <p className="question">Your Email: </p>
              <input
                type="email"
                onChange={this.handleEmail}
                className="form-control"
                placeholder="Enter email"
                id="email"
              />
            </div>
            <small className="warning">We will NOT spam you.</small>
          </form>
        </Modal.Body>
        <Modal.Footer className="btn-quotation">
          <Button variant="secondary" onClick={this.props.onHide}>
            Close
          </Button>
          <Button
            className="send-button"
            variant="primary"
            onClick={this.sendMail}
            disabled={this.state.loading}
          >
            Send Email
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SendQuotationModal;
