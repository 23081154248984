import axios from "axios"

const instance = axios.create()
instance.interceptors.request.use(function (config) {
	config.url = config.url.replace("https://www.oliveservicedapartments.com/olive_api/public/", process.env.REACT_APP_BASE_URL)
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});

export default instance