import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./upsell.css";
import axios from "../../lib/axios/axios";
import history from "../../lib/history";
import moment from "moment";
import {
  Form,
  Input,
  TextArea,
  Button,
  Select,
  Checkbox,
} from "semantic-ui-react";
import ReactDOMServer from "react-dom/server";
import createDOMPurify from "dompurify";
import { JSDOM } from "jsdom";

const window = new JSDOM("").window;
const DOMPurify = createDOMPurify(window);
//amount changes
class Upsell extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val: [],
      propertyPrice: [],
      priceType: "",
      property_price: "",
      property_price30: 0,
      property_price15: 0,
      property_less7: 0,
      property_greater7: 0,
      property_greater14: 0,
      propertyPrice15: [],
      propertyPrice30: [],
      selectedDate: "",
      isClicked: false,
      cut_price15: 0,
      cut_price30: 0,
      bookingDiscount: "",
      policeText: "",
      discountFlag: "",
      bestAvailableRate: "",
      bestAvailableRatePrice: "",
      hotDealRate: "",
      hotDealRatePrice: "",
      hideUpgrade: 0,
    };
  }

  async componentDidMount() {
    this.handleHideUpgrade();
    this.availableRate();
    this.getBookDays();
    this.getPolicies();

    console.log(
      `no of days: ${this.state.noDays} & deal price: ${this.state.dealPrice}`
    );
    const result = await axios.get(
      `https://www.oliveservicedapartments.com/olive_api/public/api/checkout/amenities`
    );
    // console.log("am",result.data.amenities);
    localStorage.setItem("amenVal", JSON.stringify(result.data.amenities));
    this.setState({
      val: result.data.amenities,
      priceType: localStorage.getItem("price_type"),
    });
    this.handleGetMore15(15);
    this.handleGetMore30(30);
  }
  handleHideUpgrade = () => {
    if (
      localStorage.getItem("apartmentTitle") === "furnished" &&
      localStorage.getItem("dealType") === "hot deal"
    ) {
      this.setState({ hideUpgrade: 1 });
    } else if (
      localStorage.getItem("apartmentTitle") === "furnished" &&
      localStorage.getItem("dealType") === "best available rate"
    ) {
      this.setState({ hideUpgrade: 2 });
    } else if (
      localStorage.getItem("apartmentTitle") === "Standard" &&
      localStorage.getItem("dealType") === "hot deal"
    ) {
      this.setState({ hideUpgrade: 3 });
    } else if (
      localStorage.getItem("apartmentTitle") === "Standard" &&
      localStorage.getItem("dealType") === "best available rate"
    ) {
      this.setState({ hideUpgrade: 4 });
    }
  };

  availableRate = () => {
    const { noguest, endDate, startDate, property_id, roomType_id } =
      this.props.queryParam;
    axios
      .get(
        `https://www.oliveservicedapartments.com/olive_api/public/api/property/${property_id}?from_date=${
          startDate && moment(startDate).format("YYYY-MM-DD")
        }&to_date=${
          endDate && moment(endDate).format("YYYY-MM-DD")
        }&guest=${noguest}&from_price=1000&to_price=10000`
      )
      .then((resp) => {
        if (resp.status == 200) {
          const propertyLayouts = resp.data.property.layouts;
          if (localStorage.getItem("dealType") === "hot deal") {
            const prices = propertyLayouts.find(
              (res) => res.roomtype_id == roomType_id
            );
            const propertyPrice = prices.prices.reduce(function (prev, cur) {
              return prev + cur[localStorage.getItem("swap_price_type")];
            }, 0);
            this.setState({ bestAvailableRatePrice: propertyPrice });
          } else if (
            localStorage.getItem("dealType") === "best available rate"
          ) {
            const prices = propertyLayouts.find(
              (res) => res.roomtype_id == roomType_id
            );
            const propertyPrice = prices.prices.reduce(function (prev, cur) {
              return prev + cur[localStorage.getItem("swap_price_type")];
            }, 0);
            this.setState({ hotDealRatePrice: propertyPrice });
          }
        }
      });
  };

  getBookDays = () => {
    const { endDate, startDate } = this.props.queryParam;
    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);

    axios({
      method: "get",
      url: `https://www.oliveservicedapartments.com/olive_api/public/api/book_days?check_in=${startDate}&check_out=${endDate}`,
      headers: { Authorization: AuthStr },
    }).then((response) => {
      if (response.status == 200) {
        const { data } = response;
        const str = data.res.content.replace(/<\/?[^>]+(>|$)/g, "");
        this.setState({ bookingDiscount: str, discountFlag: data.res.flag });
        if (data.res.flag == 1) this.handleGetLess7();
        else if (data.res.flag == 2) this.handleGetGreater7();
        else if (data.res.flag == 3) this.handleGetGreater14();
      }
    });
  };

  getPolicies = () => {
    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);

    axios({
      method: "get",
      url: `https://www.oliveservicedapartments.com/olive_api/public/api/policies`,
      headers: { Authorization: AuthStr },
    }).then((response) => {
      if (response.status == 200) {
        const { data } = response;
        var str = "";
        const apartmentType = localStorage.getItem("apartmentTitle");
        if (apartmentType === "furnished")
          str = data.policies.upgrade_superior
            ? data.policies.upgrade_superior.replace(/<\/?[^>]+(>|$)/g, "")
            : "";
        else if (apartmentType === "Standard")
          str = data.policies.upgrade_premier
            ? data.policies.upgrade_premier.replace(/<\/?[^>]+(>|$)/g, "")
            : "";
        const bestAvailableRate = data.policies.bestrate_to_hotdeal
          ? data.policies.bestrate_to_hotdeal.replace(/<\/?[^>]+(>|$)/g, "")
          : "";
        const hotDealRate = data.policies.hotdeal_to_bestrate
          ? data.policies.hotdeal_to_bestrate.replace(/<\/?[^>]+(>|$)/g, "")
          : "";
        this.setState({
          policeText: str,
          bestAvailableRate: bestAvailableRate,
          hotDealRate: hotDealRate,
        });
      }
    });
  };

  handleGetLess7 = () => {
    const { endDate, startDate } = this.props.queryParam;

    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: "https://www.oliveservicedapartments.com/olive_api/public/api/property/price",
      data: {
        property_id: localStorage.getItem("property_id"),
        guest: localStorage.getItem("noGuest"),
        roomtype_id: localStorage.getItem("roomType_id"),
        check_in: moment(startDate).format("YYYY-MM-DD"),
        check_out: moment(endDate).format("YYYY-MM-DD"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("RESPONSE", response);
        switch (this.state.priceType) {
          case "price":
            let propertyPrice1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.price;
            },
            0);
            let propertyPricecut1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprice;
            },
            0);
            this.setState({
              property_less7: propertyPrice1,
              cut_less7: propertyPricecut1,
            });
            break;

          case "refundable_price":
            let propertyPrice2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.refundable_price;
            },
            0);
            let propertyPricecut2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.drefundable_price;
            },
            0);
            this.setState({
              property_less7: propertyPrice2,
              cut_less7: propertyPricecut2,
            });
            break;

          case "snrefundable_price":
            let propertyPrice3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.snrefundable_price;
            },
            0);
            let propertyPricecut3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsnrefundable_price;
            },
            0);
            this.setState({
              property_less7: propertyPrice3,
              cut_less7: propertyPricecut3,
            });
            break;

          case "srefundable_price":
            let propertyPrice4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.srefundable_price;
            },
            0);
            let propertyPricecut4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsrefundable_price;
            },
            0);
            this.setState({
              property_less7: propertyPrice4,
              cut_less7: propertyPricecut4,
            });
            break;

          case "pnrefundable_price":
            let propertyPrice5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.pnrefundable_price;
            },
            0);
            let propertyPricecut5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dpnrefundable_price;
            },
            0);
            this.setState({
              property_less7: propertyPrice5,
              cut_less7: propertyPricecut5,
            });
            break;

          case "prefundable_price":
            let propertyPrice6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.prefundable_price;
            },
            0);
            let propertyPricecut6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprefundable_price;
            },
            0);
            this.setState({
              property_less7: propertyPrice6,
              cut_less7: propertyPricecut6,
            });
            break;
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  handleGetGreater7 = () => {
    const { endDate, startDate } = this.props.queryParam;

    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: "https://www.oliveservicedapartments.com/olive_api/public/api/property/price",
      data: {
        property_id: localStorage.getItem("property_id"),
        guest: localStorage.getItem("noGuest"),
        roomtype_id: localStorage.getItem("roomType_id"),
        check_in: moment(startDate).format("YYYY-MM-DD"),
        check_out: moment(endDate).format("YYYY-MM-DD"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("RESPONSE", response);
        switch (this.state.priceType) {
          case "price":
            let propertyPrice1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.price;
            },
            0);
            let propertyPricecut1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprice;
            },
            0);
            this.setState({
              property_greater7: propertyPrice1,
              cut_greater7: propertyPricecut1,
            });
            break;

          case "refundable_price":
            let propertyPrice2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.refundable_price;
            },
            0);
            let propertyPricecut2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.drefundable_price;
            },
            0);
            this.setState({
              property_greater7: propertyPrice2,
              cut_greater7: propertyPricecut2,
            });
            break;

          case "snrefundable_price":
            let propertyPrice3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.snrefundable_price;
            },
            0);
            let propertyPricecut3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsnrefundable_price;
            },
            0);
            this.setState({
              property_greater7: propertyPrice3,
              cut_greater7: propertyPricecut3,
            });
            break;

          case "srefundable_price":
            let propertyPrice4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.srefundable_price;
            },
            0);
            let propertyPricecut4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsrefundable_price;
            },
            0);
            this.setState({
              property_greater7: propertyPrice4,
              cut_greater7: propertyPricecut4,
            });
            break;

          case "pnrefundable_price":
            let propertyPrice5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.pnrefundable_price;
            },
            0);
            let propertyPricecut5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dpnrefundable_price;
            },
            0);
            this.setState({
              property_greater7: propertyPrice5,
              cut_greater7: propertyPricecut5,
            });
            break;

          case "prefundable_price":
            let propertyPrice6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.prefundable_price;
            },
            0);
            let propertyPricecut6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprefundable_price;
            },
            0);
            this.setState({
              property_greater7: propertyPrice6,
              cut_greater7: propertyPricecut6,
            });
            break;
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  handleGetGreater14 = () => {
    const { endDate, startDate } = this.props.queryParam;

    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: "https://www.oliveservicedapartments.com/olive_api/public/api/property/price",
      data: {
        property_id: localStorage.getItem("property_id"),
        guest: localStorage.getItem("noGuest"),
        roomtype_id: localStorage.getItem("roomType_id"),
        check_in: moment(startDate).format("YYYY-MM-DD"),
        check_out: moment(endDate).format("YYYY-MM-DD"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        console.log("RESPONSE", response);
        switch (this.state.priceType) {
          case "price":
            let propertyPrice1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.price;
            },
            0);
            let propertyPricecut1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprice;
            },
            0);
            this.setState({
              property_greater14: propertyPrice1,
              cut_greater14: propertyPricecut1,
            });
            break;

          case "refundable_price":
            let propertyPrice2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.refundable_price;
            },
            0);
            let propertyPricecut2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.drefundable_price;
            },
            0);
            this.setState({
              property_greater14: propertyPrice2,
              cut_greater14: propertyPricecut2,
            });
            break;

          case "snrefundable_price":
            let propertyPrice3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.snrefundable_price;
            },
            0);
            let propertyPricecut3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsnrefundable_price;
            },
            0);
            this.setState({
              property_greater14: propertyPrice3,
              cut_greater14: propertyPricecut3,
            });
            break;

          case "srefundable_price":
            let propertyPrice4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.srefundable_price;
            },
            0);
            let propertyPricecut4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsrefundable_price;
            },
            0);
            this.setState({
              property_greater14: propertyPrice4,
              cut_greater14: propertyPricecut4,
            });
            break;

          case "pnrefundable_price":
            let propertyPrice5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.pnrefundable_price;
            },
            0);
            let propertyPricecut5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dpnrefundable_price;
            },
            0);
            this.setState({
              property_greater14: propertyPrice5,
              cut_greater14: propertyPricecut5,
            });
            break;

          case "prefundable_price":
            let propertyPrice6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.prefundable_price;
            },
            0);
            let propertyPricecut6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprefundable_price;
            },
            0);
            this.setState({
              property_greater14: propertyPrice6,
              cut_greater14: propertyPricecut6,
            });
            break;
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  handleGetMore15 = (days) => {
    const newDate = this.addDays(days);
    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: "https://www.oliveservicedapartments.com/olive_api/public/api/property/price",
      data: {
        property_id: localStorage.getItem("property_id"),
        guest: localStorage.getItem("noGuest"),
        roomtype_id: localStorage.getItem("roomType_id"),
        check_in: moment(localStorage.getItem("startDate")).format(
          "YYYY-MM-DD"
        ),
        check_out: moment(newDate).format("YYYY-MM-DD"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        //handle success
        this.setState({
          propertyPrice15: response.data.data,
        });
        switch (this.state.priceType) {
          case "price":
            let propertyPrice1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.price;
            },
            0);
            let propertyPricecut1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprice;
            },
            0);
            this.setState({
              property_price15: propertyPrice1,
              cut_price15: propertyPricecut1,
            });
            break;

          case "refundable_price":
            let propertyPrice2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.refundable_price;
            },
            0);
            let propertyPricecut2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.drefundable_price;
            },
            0);
            this.setState({
              property_price15: propertyPrice2,
              cut_price15: propertyPricecut2,
            });
            break;

          case "snrefundable_price":
            let propertyPrice3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.snrefundable_price;
            },
            0);
            let propertyPricecut3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsnrefundable_price;
            },
            0);
            this.setState({
              property_price15: propertyPrice3,
              cut_price15: propertyPricecut3,
            });
            break;

          case "srefundable_price":
            let propertyPrice4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.srefundable_price;
            },
            0);
            let propertyPricecut4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsrefundable_price;
            },
            0);
            this.setState({
              property_price15: propertyPrice4,
              cut_price15: propertyPricecut4,
            });
            break;

          case "pnrefundable_price":
            let propertyPrice5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.pnrefundable_price;
            },
            0);
            let propertyPricecut5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dpnrefundable_price;
            },
            0);
            this.setState({
              property_price15: propertyPrice5,
              cut_price15: propertyPricecut5,
            });
            break;

          case "prefundable_price":
            let propertyPrice6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.prefundable_price;
            },
            0);
            let propertyPricecut6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprefundable_price;
            },
            0);
            this.setState({
              property_price15: propertyPrice6,
              cut_price15: propertyPricecut6,
            });
            break;
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  handleGetMore30 = (days) => {
    const newDate = this.addDays(days);
    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: "https://www.oliveservicedapartments.com/olive_api/public/api/property/price",
      data: {
        property_id: localStorage.getItem("property_id"),
        guest: localStorage.getItem("noGuest"),
        roomtype_id: localStorage.getItem("roomType_id"),
        check_in: moment(localStorage.getItem("startDate")).format(
          "YYYY-MM-DD"
        ),
        check_out: moment(newDate).format("YYYY-MM-DD"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        //handle successoptions
        this.setState({
          propertyPrice30: response.data.data,
        });
        switch (this.state.priceType) {
          case "price":
            let propertyPrice1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.price;
            },
            0);
            let propertyPricecut1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprice;
            },
            0);
            this.setState({
              property_price30: propertyPrice1,
              cut_price30: propertyPricecut1,
            });
            break;

          case "refundable_price":
            let propertyPrice2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.refundable_price;
            },
            0);
            let propertyPricecut2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.drefundable_price;
            },
            0);
            this.setState({
              property_price30: propertyPrice2,
              cut_price30: propertyPricecut2,
            });
            break;

          case "snrefundable_price":
            let propertyPrice3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.snrefundable_price;
            },
            0);
            let propertyPricecut3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsnrefundable_price;
            },
            0);
            this.setState({
              property_price30: propertyPrice3,
              cut_price30: propertyPricecut3,
            });
            break;

          case "srefundable_price":
            let propertyPrice4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.srefundable_price;
            },
            0);
            let propertyPricecut4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsrefundable_price;
            },
            0);
            console.log("property price message", propertyPrice4);
            this.setState({
              property_price30: propertyPrice4,
              cut_price30: propertyPricecut4,
            });
            break;

          case "pnrefundable_price":
            let propertyPrice5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.pnrefundable_price;
            },
            0);
            let propertyPricecut5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dpnrefundable_price;
            },
            0);
            this.setState({
              property_price30: propertyPrice5,
              cut_price30: propertyPricecut5,
            });
            break;

          case "prefundable_price":
            let propertyPrice6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.prefundable_price;
            },
            0);
            let propertyPricecut6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprefundable_price;
            },
            0);
            this.setState({
              property_price30: propertyPrice6,
              cut_price30: propertyPricecut6,
            });
            break;
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };

  handleAmenityIndex(index) {
    let amenityIndex = [];
  }

  addDays = (days) => {
    // const copy = new Date(localStorage.getItem("startDate"));
    const { startDate } = this.props.queryParam;
    const copy = new Date(startDate);

    copy.setDate(copy.getDate() + days);
    console.log(copy);
    return copy;
  };

  handleGetMore = (days) => {
    this.setState({
      selectedDate: days,
    });
    localStorage.setItem("noDays", days);
    const newDate = this.addDays(days);
    localStorage.setItem("endDate", newDate);
    localStorage.setItem("checkoutdate", newDate);

    const USER_TOKEN = JSON.parse(localStorage.getItem("login"));
    console.log(USER_TOKEN);
    const AuthStr = "Bearer ".concat(USER_TOKEN);
    axios({
      method: "post",
      url: "https://www.oliveservicedapartments.com/olive_api/public/api/property/price",
      data: {
        property_id: localStorage.getItem("property_id"),
        guest: localStorage.getItem("noGuest"),
        roomtype_id: localStorage.getItem("roomType_id"),
        check_in: moment(localStorage.getItem("startDate")).format(
          "YYYY-MM-DD"
        ),
        check_out: moment(newDate).format("YYYY-MM-DD"),
      },
      headers: { Authorization: AuthStr },
    })
      .then((response) => {
        //handle success
        this.setState({
          propertyPrice: response.data.data,
        });
        localStorage.setItem(
          "property_price",
          JSON.stringify(this.state.propertyPrice)
        );
        localStorage.setItem(
          "layouts",
          JSON.stringify(this.state.propertyPrice)
        );
        console.log("property price ", this.state.propertyPrice);
        switch (this.state.priceType) {
          case "price":
            let propertyPrice1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.price;
            },
            0);
            let propertyPricecut1 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprice;
            },
            0);
            console.log("property price message", propertyPrice1);
            localStorage.setItem("dealPrice", propertyPrice1);
            localStorage.setItem("cut_price", propertyPricecut1);
            this.props.handlePriceForMoreDays(propertyPrice1, days);
            this.setState({ property_price: propertyPrice1 });
            this.props.taxcalculation();
            break;

          case "refundable_price":
            let propertyPrice2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.refundable_price;
            },
            0);
            let propertyPricecut2 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.drefundable_price;
            },
            0);
            console.log("property price message", propertyPrice2);
            localStorage.setItem("dealPrice", propertyPrice2);
            localStorage.setItem("cut_price", propertyPricecut2);
            this.props.handlePriceForMoreDays(propertyPrice2, days);
            this.setState({ property_price: propertyPrice2 });
            this.props.taxcalculation();
            break;

          case "snrefundable_price":
            let propertyPrice3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.snrefundable_price;
            },
            0);
            let propertyPricecut3 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsnrefundable_price;
            },
            0);
            console.log("property price message", propertyPrice3);
            localStorage.setItem("dealPrice", propertyPrice3);
            localStorage.setItem("cut_price", propertyPricecut3);
            this.props.handlePriceForMoreDays(propertyPrice3, days);
            this.setState({ property_price: propertyPrice3 });
            this.props.taxcalculation();
            break;

          case "srefundable_price":
            let propertyPrice4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.srefundable_price;
            },
            0);
            let propertyPricecut4 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dsrefundable_price;
            },
            0);
            console.log("property price message", propertyPrice4);
            localStorage.setItem("dealPrice", propertyPrice4);
            localStorage.setItem("cut_price", propertyPricecut4);
            this.props.handlePriceForMoreDays(propertyPrice4, days);
            this.setState({ property_price: propertyPrice4 });
            this.props.taxcalculation();
            break;

          case "pnrefundable_price":
            let propertyPrice5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.pnrefundable_price;
            },
            0);
            let propertyPricecut5 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dpnrefundable_price;
            },
            0);
            console.log("property price message", propertyPrice5);
            localStorage.setItem("dealPrice", propertyPrice5);
            localStorage.setItem("cut_price", propertyPricecut5);
            this.props.handlePriceForMoreDays(propertyPrice5, days);
            this.setState({ property_price: propertyPrice5 });
            this.props.taxcalculation();
            break;

          case "prefundable_price":
            let propertyPrice6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.prefundable_price;
            },
            0);
            let propertyPricecut6 = response.data.data.reduce(function (
              prev,
              cur
            ) {
              return prev + cur.dprefundable_price;
            },
            0);
            console.log("property price message", propertyPrice6);
            localStorage.setItem("dealPrice", propertyPrice6);
            localStorage.setItem("cut_price", propertyPricecut6);
            this.props.handlePriceForMoreDays(propertyPrice6, days);
            this.setState({ property_price: propertyPrice6 });
            this.props.taxcalculation();
            break;
        }
      })
      .catch(function (response) {
        //handle error
        console.log("Error while calling api", response);
      });
  };
  handleApartmentType = (e) => {
    const previewsDealPrice = localStorage.getItem("dealPrice");
    const previewsCutPrice = localStorage.getItem("cut_price");
    localStorage.setItem("previewsDealPrice", previewsDealPrice);
    localStorage.setItem("previewsCutPrice", previewsCutPrice);

    // let stdToSupHot = localStorage.getItem("stdToSupHot");
    // let stdToSupHotFree = localStorage.getItem("stdToSupHotFree");
    // let supToPre = localStorage.getItem("supToPre");
    // let supToPreFree = localStorage.getItem("supToPreFree");
    // let deal_price_pre = localStorage.getItem("deal_price_pre");
    // let deal_price_pre_free = localStorage.getItem("deal_price_pre_free");
    // // cut price
    // let stdToSupHotcut = localStorage.getItem("stdToSupHotcut");
    // let stdToSupHotFreecut = localStorage.getItem("stdToSupHotFreecut");
    // let supToPrecut = localStorage.getItem("supToPrecut");
    // let supToPreFreecut = localStorage.getItem("supToPreFreecut");
    // let deal_price_pre_cut = localStorage.getItem("deal_price_pre_cut");
    // let deal_price_pre_free_cut = localStorage.getItem(
    //   "deal_price_pre_free_cut"
    // );

    if (
      localStorage.getItem("apartmentTitle") === "furnished" &&
      localStorage.getItem("dealType") === "hot deal"
    ) {
      localStorage.setItem("apartmentTitle", "Standard");
      localStorage.setItem("dealPrice", localStorage.getItem("deal_price_sup"));
      localStorage.setItem("cut_price", localStorage.getItem("cut_price_sup"));
      localStorage.setItem("cutprice", localStorage.getItem("cutprice_sup"));
      localStorage.setItem("price_type", "snrefundable_price");
      localStorage.setItem(
        "swapDealPrice",
        localStorage.getItem("deal_price_sup_free")
      );
      localStorage.setItem(
        "swapCut_Price",
        localStorage.getItem("cut_price_sup_free")
      );
      localStorage.setItem(
        "swapCutPrice",
        localStorage.getItem("cutprice_sup_free")
      );
      localStorage.setItem("swap_price_type", "srefundable_price");

      localStorage.setItem("aprtment_type_code", 2);
      localStorage.setItem(
        "property_amenity",
        JSON.stringify(JSON.parse(localStorage.getItem("property_amenity_sup")))
      );
      localStorage.removeItem("amenity_details");
      localStorage.removeItem("amenity_price");
      localStorage.removeItem("amenityDetail");
      localStorage.removeItem("amenitieslist");
      // localStorage.setItem("dealPrice", stdToSupHotFree);
      // this.setState(
      //   {
      //     totalPrice: localStorage.setItem("dealPrice", stdToSupHot),
      //   },
      //   () => {
      //     console.log("calculation", this.state.totalPrice);
      //   }
      // );
      // this.props.taxcalculation();
      // localStorage.setItem("supToPre", deal_price_pre);
      // localStorage.setItem("supToPrecut", deal_price_pre_cut);
      // localStorage.setItem(
      //   "cutprice_sup_pre",
      //   localStorage.getItem("cutprice_pre")
      // );
    } else if (
      localStorage.getItem("apartmentTitle") === "furnished" &&
      localStorage.getItem("dealType") === "best available rate"
    ) {
      localStorage.setItem("apartmentTitle", "Standard");
      localStorage.setItem(
        "dealPrice",
        localStorage.getItem("deal_price_sup_free")
      );
      localStorage.setItem(
        "cut_price",
        localStorage.getItem("cut_price_sup_free")
      );
      localStorage.setItem(
        "cutprice",
        localStorage.getItem("cutprice_sup_free")
      );
      localStorage.setItem("price_type", "srefundable_price");
      localStorage.setItem(
        "swapDealPrice",
        localStorage.getItem("deal_price_sup")
      );
      localStorage.setItem(
        "swapCut_Price",
        localStorage.getItem("cut_price_sup")
      );
      localStorage.setItem(
        "swapCutPrice",
        localStorage.getItem("cutprice_sup")
      );
      localStorage.setItem("swap_price_type", "snrefundable_price");

      localStorage.setItem("aprtment_type_code", 2);
      localStorage.removeItem("amenity_details");
      localStorage.removeItem("amenity_price");
      localStorage.removeItem("amenityDetail");
      localStorage.removeItem("amenitieslist");
      localStorage.setItem(
        "property_amenity",
        JSON.stringify(JSON.parse(localStorage.getItem("property_amenity_sup")))
      );
      // localStorage.setItem("dealPrice", stdToSupHotFree);
      // this.setState(
      //   {
      //     totalPrice: localStorage.setItem("dealPrice", stdToSupHotFree),
      //   },
      //   () => {
      //     console.log("calculation", this.state.totalPrice);
      //   }
      // );

      // this.props.taxcalculation();

      // localStorage.setItem("supToPreFree", deal_price_pre_free);
      // localStorage.setItem("supToPreFreecut", deal_price_pre_free_cut);
      // localStorage.setItem(
      //   "cutprice_sup_pre_free",
      //   localStorage.getItem("cutprice_pre_free")
      // );
    } else if (
      localStorage.getItem("apartmentTitle") === "Standard" &&
      localStorage.getItem("dealType") === "hot deal"
    ) {
      localStorage.setItem("apartmentTitle", "Superior");
      localStorage.setItem("dealPrice", localStorage.getItem("deal_price_pre"));
      localStorage.setItem("cut_price", localStorage.getItem("cut_price_pre"));
      localStorage.setItem("cutprice", localStorage.getItem("cutprice_pre"));
      localStorage.setItem("price_type", "pnrefundable_price");
      localStorage.setItem(
        "swapDealPrice",
        localStorage.getItem("deal_price_pre_free")
      );
      localStorage.setItem(
        "swapCut_Price",
        localStorage.getItem("deal_price_pre_free")
      );
      localStorage.setItem(
        "swapCutPrice",
        localStorage.getItem("cutprice_pre_free")
      );
      localStorage.setItem("swap_price_type", "prefundable_price");

      localStorage.setItem("aprtment_type_code", 3);
      localStorage.removeItem("amenity_details");
      localStorage.removeItem("amenity_price");
      localStorage.removeItem("amenityDetail");
      localStorage.removeItem("amenitieslist");
      localStorage.removeItem("property_amenity");
      localStorage.setItem(
        "property_amenity",
        JSON.stringify(JSON.parse(localStorage.getItem("property_amenity_pre")))
      );
      // localStorage.setItem("dealPrice", stdToSupHotFree);
      // this.setState(
      //   {
      //     totalPrice: localStorage.setItem("dealPrice", supToPre),
      //   },
      //   () => {
      //     console.log("calculation", this.state.totalPrice);
      //   }
      // );

      // this.props.taxcalculation();
    } else {
      localStorage.setItem("apartmentTitle", "Superior");
      localStorage.setItem(
        "dealPrice",
        localStorage.getItem("deal_price_pre_free")
      );
      localStorage.setItem(
        "cut_price",
        localStorage.getItem("cut_price_pre_free")
      );
      localStorage.setItem(
        "cutprice",
        localStorage.getItem("cutprice_pre_free")
      );
      localStorage.setItem("price_type", "prefundable_price");
      localStorage.setItem(
        "swapDealPrice",
        localStorage.getItem("deal_price_pre")
      );
      localStorage.setItem(
        "swapCut_Price",
        localStorage.getItem("cut_price_pre")
      );
      localStorage.setItem(
        "swapCutPrice",
        localStorage.getItem("cutprice_pre")
      );
      localStorage.setItem("swap_price_type", "pnrefundable_price");

      localStorage.setItem("aprtment_type_code", 3);
      localStorage.removeItem("amenity_details");
      localStorage.removeItem("amenity_price");
      localStorage.removeItem("amenityDetail");
      localStorage.removeItem("amenitieslist");
      localStorage.removeItem("property_amenity");
      localStorage.setItem(
        "property_amenity",
        JSON.stringify(JSON.parse(localStorage.getItem("property_amenity_pre")))
      );
      // this.setState(
      //   {
      //     totalPrice: localStorage.setItem("dealPrice", supToPreFree),
      //   },
      //   () => {
      //     console.log("calculation", this.state.totalPrice);
      //   }
      // );

      // this.props.taxcalculation();
    }
    // e.preventDefault();

    this.setState({
      priceType: localStorage.getItem("price_type"),
    });
    this.handleHideUpgrade();
    this.getBookDays();
    this.availableRate();
    this.handleDeSelect();

    console.log(
      "INNNN",
      localStorage.getItem("cut_price"),
      localStorage.getItem("noDays")
    );
  };

  handleDealChange = (e) => {
    let dealPrice = localStorage.getItem("dealPrice");
    let cut_price = localStorage.getItem("cut_price");
    let cutprice = localStorage.getItem("cutprice");
    let price_type = localStorage.getItem("price_type");
    let cancel_type = localStorage.getItem("cancelType");
    let cancel_type_img = localStorage.getItem("cancelTypeImage");

    let swapDealPrice = localStorage.getItem("swapDealPrice");
    let swapCut_Price = localStorage.getItem("swapCut_Price");
    let swapCutPrice = localStorage.getItem("swapCutPrice");
    let swap_price_type = localStorage.getItem("swap_price_type");
    let swap_cancel_type = localStorage.getItem("swapCancelType");
    let swap_cancel_type_img = localStorage.getItem("swapCancelTypeImage");

    if (localStorage.getItem("dealType") == "hot deal") {
      localStorage.setItem("dealType", "best available rate");
    } else if (localStorage.getItem("dealType") == "best available rate") {
      localStorage.setItem("dealType", "hot deal");
    }

    localStorage.setItem("dealPrice", swapDealPrice);
    localStorage.setItem("cut_price", swapCut_Price);
    localStorage.setItem("cutprice", swapCutPrice);
    localStorage.setItem("price_type", swap_price_type);
    localStorage.setItem("cancelType", swap_cancel_type);
    localStorage.setItem("cancelTypeImage", swap_cancel_type_img);

    localStorage.setItem("swapDealPrice", dealPrice);
    localStorage.setItem("swapCut_Price", cut_price);
    localStorage.setItem("swapCutPrice", cutprice);
    localStorage.setItem("swap_price_type", price_type);
    localStorage.setItem("swapCancelType", cancel_type);
    localStorage.setItem("swapCancelTypeImage", cancel_type_img);

    this.setState({
      priceType: localStorage.getItem("price_type"),
    });
    this.handleHideUpgrade();
    this.getBookDays();
    this.handleDeSelect();
  };

  handleDeSelect = () => {
    this.props.handleDeSelect(
      localStorage.getItem("no_days"),
      localStorage.getItem("checkout"),
      localStorage.getItem("dealPrice"),
      JSON.parse(localStorage.getItem("layouts")),
      localStorage.getItem("cut_price_back")
    );
    this.setState({
      selectedDate: 0,
    });
  };

  render() {
    const regex = /(<([^>]+)>)/gi;
    const result = JSON.parse(localStorage.getItem("alltooltips"))
      ? JSON.parse(localStorage.getItem("alltooltips")).thirtydays.replace(
          regex,
          ""
        )
      : "";
    const renderHTML = (escapedHTML) =>
      React.createElement("div", {
        dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(escapedHTML) },
      });

    console.log("CUT PRICE", localStorage.getItem("dealPrice"));
    console.log("CUT PRICE1", localStorage.getItem("cut_price"));
    console.log("CUT PRICE2", localStorage.getItem("noDays"));

    const amenityDetail = JSON.parse(localStorage.getItem("amenity_details"));
    console.log(
      "amenity detail from upsell ===============>>>>>>>>>>>>>>",
      amenityDetail
    );
    return (
      <React.Fragment>
        <p className="fw-700 font-cap font-green font-27">
          Add-On Service Packs (Optional)
        </p>
        {this.state.val.map((amenity, index) =>
          amenity.room_type ===
          parseInt(localStorage.getItem("aprtment_type_code")) ? (
            <div key={amenity.id} className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img alt={amenity.amenities_title} className="" src={amenity.image} />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">
                    {amenity.amenities_title}
                  </p>
                  <p className="p4">
                    {amenity.description}
                    {/* Fast wi-fi is accessible all around the apartment. */}
                  </p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price">
                    <p className="font-green p2 fw-700">
                      {amenity.price_type === 1 ? (
                        <React.Fragment>
                          &#8377;{" "}
                          {parseFloat(
                            (localStorage.getItem("dealPrice") *
                              amenity.price) /
                              100
                          ).toFixed()}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          &#8377; {parseFloat(amenity.price).toFixed()}
                        </React.Fragment>
                      )}
                    </p>
                    <button
                      key={index}
                      className="listing-btn"
                      onClick={() => {
                        // this.changeButtonText();
                        this.props.addAmenity(
                          amenity.id,
                          amenity.price_type === 1
                            ? parseFloat(
                                (localStorage.getItem("dealPrice") *
                                  amenity.price) /
                                  100
                              ).toFixed()
                            : amenity.price,
                          amenity.amenities_title
                        );
                        console.log(
                          "amenity detail from upsell",
                          this.props.amenityDetail
                        );
                        /* eslint no-restricted-globals:0 */
                        // let object = this.props.amenityDetail.find(o => o.amenityName === amenity.amenities_title);
                        // console.info("object here", object);
                        //   object[0].amenityName===amenity.amenities_title?this.setState({add: "Added"}):this.setState({add: "Add"})
                        // this.props.addAmenityName(amenity.amenities_title);
                      }}
                      // >{this.props.selectedKey}</button>
                    >
                      {/* {this.props.selectedKey} */}
                      {amenityDetail !== null &&
                      amenityDetail.find(
                        (a) => a["amenityName"] === amenity.amenities_title
                      )
                        ? "remove"
                        : "add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )
        )}
        {/* <div className="addamentycard mb-15">
          <div className="row">
            <div className="amenty-img col-md-1 col-2">
              <img className="" src={require("../../assets/Cleaning_Circle.png")} />
            </div>
            <div className="amenty-desc col-md-7 col-10">
              <p className="fw-700 p4 font-cap">Paid cleaning</p>
              <p className="p4">
                Fee charged by host to cover the cost of cleaning.
              </p>
            </div>
            <div className=" col-md-4">
              <div className="amenty-price">
                <p className="font-green p2 fw-700">&#8377; 1000</p>
                <button className="listing-btn">Add</button>
              </div>
            </div>
          </div>
        </div>
        <div className="addamentycard mb-15">
          <div className="row">
            <div className="amenty-img col-md-1 col-2">
              <img className="" src={require("../../assets/Breakfast_Circle.png")} />
            </div>
            <div className="amenty-desc col-md-7 col-10">
              <p className="fw-700 p4 font-cap">english breakfast (egg, bakon etc)</p>
              <p className="p4">
                update your regular breakfast to english  breakfast.
              </p>
            </div>
            <div className=" col-md-4">
              <div className="amenty-price">
                <p className="font-green p2 fw-700">&#8377; 1000</p>
                <button className="listing-btn">Add</button>
              </div>
            </div>
          </div>
        </div> */}
        {/* <p className="fw-700 font-cap mt-30">get more with more days</p>
        <div className="addamentycard mb-15">
          <div className="row">
            <div className="amenty-img col-md-1 col-2">
              <img className="" src={require("../../assets/Apartment.png")} />
            </div>
            <div className="amenty-desc col-md-7 col-10">
              <p className="fw-700 p4 font-cap">
                Book for 15 Days from the date of Check-In only at
              </p>
              <p className="p4">
                {JSON.parse(localStorage.getItem("alltooltips")).fifteendays}
              </p>
            </div>
            <div className=" col-md-4">
              <div className="amenty-price getmore">
                <p className="font-green p2 fw-700">
                  <strike>&#8377; {this.state.cut_price15} </strike> <br />
                  &#8377; {this.state.property_price15}
                </p>
                <button
                  className="listing-btn"
                  key={1}
                  onClick={
                    this.state.selectedDate != 15
                      ? () => {
                          this.handleGetMore(15);
                        }
                      : () => {
                          this.handleDeSelect();
                        }
                  }
                >
                  {this.state.selectedDate === 15 ? "selected" : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div> */}
        {/* {localStorage.getItem("noDays") < 30 && (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={require("../../assets/Apartment.png")}
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p4 font-cap">
                    Book for 30 Days from the date of Check-In only at
                  </p>
                  <p className="p4">{renderHTML(result)}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      <strike>&#8377; {this.state.cut_price30}</strike> <br />
                      &#8377; {this.state.property_price30}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={
                        this.state.selectedDate != 30
                          ? () => {
                              this.handleGetMore(30);
                            }
                          : () => {
                              this.handleDeSelect();
                            }
                      }
                    >
                      {this.state.selectedDate === 30 ? "selected" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )} */}
        <div className="hr-line-sep"></div>
        {localStorage.getItem("noDays") < 7 && this.state.discountFlag == 1 ? (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={
                      (process.env.REACT_APP_LISTINGS === "multi" &&
                        require("../../assets/Apartment.png")) ||
                      require("../../assets/Apartment-bluo.png")
                    }
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">Stay Longer & Save More</p>

                  <p className="p4">{renderHTML(this.state.bookingDiscount)}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      <strike>
                        &#8377; {parseFloat(this.state.cut_less7).toFixed()}
                      </strike>{" "}
                      <br />
                      &#8377; {parseFloat(this.state.property_less7).toFixed()}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={
                        this.state.selectedDate < 7
                          ? () => {
                              this.handleGetMore(7);
                              this.props.setDiscountType("Weekly");
                            }
                          : () => {
                              this.handleDeSelect();
                            }
                      }
                    >
                      {this.state.selectedDate == 7 ? "selected" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : localStorage.getItem("noDays") >= 7 &&
          localStorage.getItem("noDays") < 14 &&
          this.state.discountFlag == 2 ? (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={
                      (process.env.REACT_APP_LISTINGS === "multi" &&
                        require("../../assets/Apartment.png")) ||
                      require("../../assets/Apartment-bluo.png")
                    }
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">
                    Book for more than 7 night and less than 14 and from the
                    date of Check-In only at
                  </p>

                  <p className="p4">{renderHTML(this.state.bookingDiscount)}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      <strike>
                        &#8377; {parseFloat(this.state.cut_greater7).toFixed()}
                      </strike>{" "}
                      <br />
                      &#8377;{" "}
                      {parseFloat(this.state.property_greater7).toFixed()}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={
                        this.state.selectedDate > 7 &&
                        this.state.selectedDate < 14
                          ? () => {
                              this.handleDeSelect();
                            }
                          : () => {
                              this.handleGetMore(14);
                              this.props.setDiscountType("15 days");
                            }
                      }
                    >
                      {this.state.selectedDate > 7 &&
                      this.state.selectedDate < 14
                        ? "selected"
                        : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : localStorage.getItem("noDays") >= 14 &&
          this.state.discountFlag == 3 ? (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={
                      (process.env.REACT_APP_LISTINGS === "multi" &&
                        require("../../assets/Apartment.png")) ||
                      require("../../assets/Apartment-bluo.png")
                    }
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">
                    Book for more than 14 night and less than 28 and from the
                    date of Check-In only at
                  </p>

                  <p className="p4">{renderHTML(this.state.bookingDiscount)}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      <strike>
                        &#8377; {parseFloat(this.state.cut_greater14).toFixed()}
                      </strike>{" "}
                      <br />
                      &#8377;{" "}
                      {parseFloat(this.state.property_greater14).toFixed()}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={
                        this.state.selectedDate > 14
                          ? () => {
                              this.handleDeSelect();
                            }
                          : () => {
                              this.handleGetMore(28);
                              this.props.setDiscountType("28 days");
                            }
                      }
                    >
                      {this.state.selectedDate === 14 ? "selected" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : localStorage.getItem("noDays") > 28 &&
          this.state.discountFlag == 4 ? (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={
                      (process.env.REACT_APP_LISTINGS === "multi" &&
                        require("../../assets/Apartment.png")) ||
                      require("../../assets/Apartment-bluo.png")
                    }
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">
                    Book for more than 28 night and from the date of Check-In
                    only at
                  </p>

                  <p className="p4">{renderHTML(this.state.bookingDiscount)}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      <strike>
                        &#8377; {parseFloat(this.state.cut_price30).toFixed()}
                      </strike>{" "}
                      <br />
                      &#8377;{" "}
                      {parseFloat(this.state.property_price30).toFixed()}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={
                        this.state.selectedDate != 30
                          ? () => {
                              this.handleGetMore(30);
                              this.props.setDiscountType("Monthly");
                            }
                          : () => {
                              this.handleDeSelect();
                            }
                      }
                    >
                      {this.state.selectedDate === 30 ? "selected" : "Add"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}

        {localStorage.getItem("dealType") === "hot deal" ? (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={
                      (process.env.REACT_APP_LISTINGS === "multi" &&
                        require("../../assets/Apartment.png")) ||
                      require("../../assets/Apartment-bluo.png")
                    }
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">
                    {" "}
                    FREE Cancellation & Pay on Arrival via Best Available Rate
                  </p>
                  <p className="p4">{this.state.bestAvailableRate}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      &#8377;{" "}
                      {(
                        this.state.bestAvailableRatePrice /
                        localStorage.getItem("noDays")
                      ).toFixed()}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={() => {
                        // localStorage.setItem(
                        //   "cut_price",
                        //   this.state.bestAvailableRatePrice
                        // );
                        // localStorage.setItem(
                        //   "dealPrice",
                        //   this.state.bestAvailableRatePrice
                        // );
                        // localStorage.setItem("dealType", "best available rate");
                        this.props.handlePriceForMoreDays(10, 10);
                        this.handleDealChange();
                        this.availableRate();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
        {localStorage.getItem("dealType") === "best available rate" ? (
          <React.Fragment>
            <div className="addamentycard mb-15">
              <div className="row">
                <div className="amenty-img col-md-1 col-2">
                  <img
                    className=""
                    src={
                      (process.env.REACT_APP_LISTINGS === "multi" &&
                        require("../../assets/Apartment.png")) ||
                      require("../../assets/Apartment-bluo.png")
                    }
                  />
                </div>
                <div className="amenty-desc col-md-7 col-10">
                  <p className="fw-700 p3 font-cap">
                    {" "}
                    Lower Prices when you book HOT DEAL Rates
                  </p>
                  <p className="p4">{this.state.hotDealRate}</p>
                </div>
                <div className=" col-md-4">
                  <div className="amenty-price getmore">
                    <p className="font-green p2 fw-700">
                      &#8377;{" "}
                      {(
                        this.state.hotDealRatePrice /
                        localStorage.getItem("noDays")
                      ).toFixed()}
                    </p>
                    <button
                      className="listing-btn"
                      key={2}
                      onClick={() => {
                        console.log("BEST AVAILABLE RATE");

                        // localStorage.setItem(
                        //   "cut_price",
                        //   this.state.hotDealRatePrice
                        // );
                        // localStorage.setItem(
                        //   "dealPrice",
                        //   this.state.hotDealRatePrice
                        // );

                        // localStorage.setItem("dealType", "hot deal");
                        this.props.handlePriceForMoreDays(10, 10);
                        this.handleDealChange();
                        this.availableRate();
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}

        {process.env.REACT_APP_LISTINGS == "multi" && (
          <>
            {this.state.hideUpgrade == 1 ? (
              <>
                {localStorage.getItem("apartmentTitle") === "furnished" &&
                localStorage.getItem("dealType") === "hot deal" ? (
                  <React.Fragment>
                    <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={
                              (process.env.REACT_APP_LISTINGS === "multi" &&
                                require("../../assets/Apartment.png")) ||
                              require("../../assets/Apartment-bluo.png")
                            }
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p3 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700">
                              {" "}
                              Superior{" "}
                            </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {parseFloat(
                                localStorage.getItem("deal_price_sup")
                              ).toFixed()}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                // localStorage.setItem("hideUpgrade", 1);
                                this.handleApartmentType();
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={require("../../assets/Apartment.png")}
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p4 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700">
                              {" "}
                              Superior{" "}
                            </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377; {localStorage.getItem("stdToSupHot")}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                const previewsDealPrice = localStorage.getItem(
                                  "previewsDealPrice"
                                );
                                const previewsCutPrice = localStorage.getItem(
                                  "previewsCutPrice"
                                );
                                localStorage.setItem(
                                  "dealPrice",
                                  previewsDealPrice
                                );
                                localStorage.setItem(
                                  "cut_price",
                                  previewsCutPrice
                                );
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </React.Fragment>
                )}
              </>
            ) : (
              ""
            )}
            {this.state.hideUpgrade == 2 ? (
              <>
                {localStorage.getItem("apartmentTitle") === "furnished" &&
                localStorage.getItem("dealType") === "best available rate" ? (
                  <React.Fragment>
                    <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={
                              (process.env.REACT_APP_LISTINGS === "multi" &&
                                require("../../assets/Apartment.png")) ||
                              require("../../assets/Apartment-bluo.png")
                            }
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p3 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700">
                              {" "}
                              Superior{" "}
                            </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {localStorage.getItem("deal_price_sup_free") !=
                              undefined
                                ? parseFloat(
                                    localStorage.getItem("deal_price_sup_free")
                                  ).toFixed()
                                : 0}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                this.handleApartmentType();
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={require("../../assets/Apartment.png")}
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p4 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700">
                              {" "}
                              Superior{" "}
                            </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {localStorage.getItem("stdToSupHotFree") !=
                              undefined
                                ? localStorage.getItem("stdToSupHotFree")
                                : 0}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                const previewsDealPrice = localStorage.getItem(
                                  "previewsDealPrice"
                                );
                                const previewsCutPrice = localStorage.getItem(
                                  "previewsCutPrice"
                                );
                                localStorage.setItem(
                                  "dealPrice",
                                  previewsDealPrice
                                );
                                localStorage.setItem(
                                  "cut_price",
                                  previewsCutPrice
                                );
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </React.Fragment>
                )}
              </>
            ) : (
              ""
            )}
            {this.state.hideUpgrade == 3 ? (
              <>
                {localStorage.getItem("apartmentTitle") === "Standard" &&
                localStorage.getItem("dealType") === "hot deal" ? (
                  <React.Fragment>
                    <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={
                              (process.env.REACT_APP_LISTINGS === "multi" &&
                                require("../../assets/Apartment.png")) ||
                              require("../../assets/Apartment-bluo.png")
                            }
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p3 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700"> Premier </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {localStorage.getItem("deal_price_pre") !=
                              undefined
                                ? parseFloat(
                                    localStorage.getItem("deal_price_pre")
                                  ).toFixed()
                                : 0}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                this.handleApartmentType();
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={require("../../assets/Apartment.png")}
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p4 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700"> Premier </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {localStorage.getItem("supToPre") != undefined
                                ? localStorage.getItem("supToPre")
                                : 0}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                const previewsDealPrice = localStorage.getItem(
                                  "previewsDealPrice"
                                );
                                const previewsCutPrice = localStorage.getItem(
                                  "previewsCutPrice"
                                );
                                localStorage.setItem(
                                  "dealPrice",
                                  previewsDealPrice
                                );
                                localStorage.setItem(
                                  "cut_price",
                                  previewsCutPrice
                                );
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </React.Fragment>
                )}
              </>
            ) : (
              ""
            )}
            {this.state.hideUpgrade == 4 ? (
              <>
                {localStorage.getItem("apartmentTitle") === "Standard" &&
                localStorage.getItem("dealType") === "best available rate" ? (
                  <React.Fragment>
                    <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={
                              (process.env.REACT_APP_LISTINGS === "multi" &&
                                require("../../assets/Apartment.png")) ||
                              require("../../assets/Apartment-bluo.png")
                            }
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p3 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700"> Premier </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {localStorage.getItem("deal_price_pre_free") !=
                              undefined
                                ? parseFloat(
                                    localStorage.getItem("deal_price_pre_free")
                                  ).toFixed()
                                : 0}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                this.handleApartmentType();
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {/* <div className="addamentycard mb-15">
                      <div className="row">
                        <div className="amenty-img col-md-1 col-2">
                          <img
                            className=""
                            src={require("../../assets/Apartment.png")}
                          />
                        </div>
                        <div className="amenty-desc col-md-7 col-10">
                          <p className="fw-700 p4 font-cap">
                            Upgrade to{" "}
                            <span className="font-green fw-700"> Premier </span>
                            for only
                          </p>
                          <p className="p4">{this.state.policeText}</p>
                        </div>
                        <div className=" col-md-4">
                          <div className="amenty-price getmore">
                            <p className="font-green p2 fw-700">
                              &#8377;{" "}
                              {localStorage.getItem("supToPreFree") != undefined
                                ? localStorage.getItem("supToPreFree")
                                : 0}
                            </p>
                            <button
                              className="listing-btn"
                              key={2}
                              onClick={() => {
                                const previewsDealPrice = localStorage.getItem(
                                  "previewsDealPrice"
                                );
                                const previewsCutPrice = localStorage.getItem(
                                  "previewsCutPrice"
                                );
                                localStorage.setItem(
                                  "dealPrice",
                                  previewsDealPrice
                                );
                                localStorage.setItem(
                                  "cut_price",
                                  previewsCutPrice
                                );
                                this.props.handlePriceForMoreDays(10, 10);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </React.Fragment>
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
        {/* {process.env.REACT_APP_LISTINGS == "multi" && (
          <>
            {localStorage.getItem("apartmentTitle") === "furnished" &&
            localStorage.getItem("dealType") === "hot deal" ? (
              <React.Fragment>
                <div className="addamentycard mb-15">
                  <div className="row">
                    <div className="amenty-img col-md-1 col-2">
                      <img
                        className=""
                        src={require("../../assets/Apartment.png")}
                      />
                    </div>
                    <div className="amenty-desc col-md-7 col-10">
                      <p className="fw-700 p4 font-cap">
                        Upgrade to{" "}
                        <span className="font-green fw-700"> Superior </span>
                        for only
                      </p>
                      <p className="p4">{this.state.policeText}</p>
                    </div>
                    <div className=" col-md-4">
                      <div className="amenty-price getmore">
                        <p className="font-green p2 fw-700">
                          &#8377; {localStorage.getItem("stdToSupHot")}
                        </p>
                        <button
                          className="listing-btn"
                          key={2}
                          onClick={() => {
                            this.handleApartmentType();
                            this.props.handlePriceForMoreDays(10, 10);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : localStorage.getItem("apartmentTitle") === "furnished" &&
              localStorage.getItem("dealType") === "best available rate" ? (
              <React.Fragment>
                <div className="addamentycard mb-15">
                  <div className="row">
                    <div className="amenty-img col-md-1 col-2">
                      <img
                        className=""
                        src={require("../../assets/Apartment.png")}
                      />
                    </div>
                    <div className="amenty-desc col-md-7 col-10">
                      <p className="fw-700 p4 font-cap">
                        Upgrade to{" "}
                        <span className="font-green fw-700"> Superior </span>
                        for only
                      </p>
                      <p className="p4">{this.state.policeText}</p>
                    </div>
                    <div className=" col-md-4">
                      <div className="amenty-price getmore">
                        <p className="font-green p2 fw-700">
                          &#8377;{" "}
                          {localStorage.getItem("stdToSupHotFree") != undefined
                            ? localStorage.getItem("stdToSupHotFree")
                            : 0}
                        </p>
                        <button
                          className="listing-btn"
                          key={2}
                          onClick={() => {
                            this.handleApartmentType();
                            this.props.handlePriceForMoreDays(10, 10);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : localStorage.getItem("apartmentTitle") === "Standard" &&
              localStorage.getItem("dealType") === "hot deal" ? (
              <React.Fragment>
                <div className="addamentycard mb-15">
                  <div className="row">
                    <div className="amenty-img col-md-1 col-2">
                      <img
                        className=""
                        src={require("../../assets/Apartment.png")}
                      />
                    </div>
                    <div className="amenty-desc col-md-7 col-10">
                      <p className="fw-700 p4 font-cap">
                        Upgrade to{" "}
                        <span className="font-green fw-700"> Premier </span>
                        for only
                      </p>
                      <p className="p4">{this.state.policeText}</p>
                    </div>
                    <div className=" col-md-4">
                      <div className="amenty-price getmore">
                        <p className="font-green p2 fw-700">
                          &#8377;{" "}
                          {localStorage.getItem("supToPre") != undefined
                            ? localStorage.getItem("supToPre")
                            : 0}
                        </p>
                        <button
                          className="listing-btn"
                          key={2}
                          onClick={() => {
                            this.handleApartmentType();
                            this.props.handlePriceForMoreDays(10, 10);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : localStorage.getItem("apartmentTitle") === "Standard" &&
              localStorage.getItem("dealType") === "best available rate" ? (
              <React.Fragment>
                <div className="addamentycard mb-15">
                  <div className="row">
                    <div className="amenty-img col-md-1 col-2">
                      <img
                        className=""
                        src={require("../../assets/Apartment.png")}
                      />
                    </div>
                    <div className="amenty-desc col-md-7 col-10">
                      <p className="fw-700 p4 font-cap">
                        Upgrade to{" "}
                        <span className="font-green fw-700"> Premier </span>
                        for only
                      </p>
                      <p className="p4">{this.state.policeText}</p>
                    </div>
                    <div className=" col-md-4">
                      <div className="amenty-price getmore">
                        <p className="font-green p2 fw-700">
                          &#8377;{" "}
                          {localStorage.getItem("supToPreFree") != undefined
                            ? localStorage.getItem("supToPreFree")
                            : 0}
                        </p>
                        <button
                          className="listing-btn"
                          key={2}
                          onClick={() => {
                            this.handleApartmentType();
                            this.props.handlePriceForMoreDays(10, 10);
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </>
        )} */}
        <div className="fl-100 text-center">
          <button
            onClick={() => this.props.handleClick()}
            className="listing-btn mt-30 mb-30 wid-60 pad2050"
          >
            Continue booking
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default Upsell;
