import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import "./staticpages.css";
import axios from "../../lib/axios/axios";

import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);
class Lowprice extends Component {
  state = {
    response: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.handleAmenityDetail();
    // axios.get(`/be/products/getAll.json`).then(res => {
    //     console.log('result', res.data)
    axios({
      method: "get",
      url: `https://www.oliveservicedapartments.com/olive_api/public/api/lowest_price_guarantee`,
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ response: response.data });
      }
    });
  }

  render() {
    const { response } = this.state;
    const renderHTML = (escapedHTML) =>
      React.createElement("p", {
        dangerouslySetInnerHTML: {
          __html: DOMPurify.sanitize(escapedHTML),
        },
        className: "p2",
      });
    return (
      <React.Fragment>
        {process.env.REACT_APP_LISTINGS === "single" && (
          <Helmet>
            <title>
              Lowest Price Guarantee for BluO Stays Rental Apartments
            </title>
            <meta
              name="description"
              content="Lowest Price Guarantee for BluO Stays - We guarantee that our Website offers the Best Pricing for BluO Stays, you won't get better rates anywhere else!
              "
            />
          </Helmet>
        )}
        {process.env.REACT_APP_LISTINGS === "multi" && (
          <Helmet>
            <title>
              Lowest Price Guarantee for Olive Stays Rental Apartments
            </title>
            <meta
              name="description"
              content="Lowest Price Guarantee for BluO Stays - We guarantee that our Website offers the Best Pricing for Olive Stays, you won't get better rates anywhere else!
              "
            />
          </Helmet>
        )}

        <section className="pad-45">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="listing-card ">
                  <h1 class="font-green mb-30 font-cap text-center">
                    Lowest Price Guarantee
                  </h1>
                </div>
              </div>
              {response &&
                response.length > 0 &&
                response.map((res, index) => (
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">{res.title}</p>
                      {renderHTML(res.content)}
                    </div>
                  </div>
                ))}
              {/* {process.env.REACT_APP_LISTINGS === "single" && (
                <>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">
                        BluO Stays - Lowest Price Guarantee
                      </p>
                      <p className="p2 ">
                        We want you to feel fully confident that when you book
                        an apartment using our BluO Stays Lowest Price Guarantee
                        program, you are assured to receive the guaranteed
                        lowest rate for your apartment. When you make your
                        booking on our website, you can be 100% satisfied that
                        it’s the lowest possible price for that property. You
                        will NOT get a lower or even matching price on any other
                        travel website. We are so confident of our claim that we
                        are willing to back it up with an unbeatable offer - if
                        you find a lower qualified price for the same apartment
                        on any other website within 24 hours of making your
                        booking directly on our website, then we'll match that
                        rate and also give you an extra 25% discount or a
                        complimentary upgrade! The lower qualified price is
                        defined as that of the same property and booking terms
                        as your current booking, offered on any websites besides
                        our own channels, and without any restrictions for it to
                        be viewable and bookable by anyone. Please Email us your
                        claim to sales@staybluo.com
                        <a
                          className="static-links"
                          href="mailto:sales@staybluo.com"
                        >
                          sales@staybluo.com
                        </a>{" "}
                        so that we can verify and respond with a resolution.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">Why Book Direct with BluO Stays?</p>
                      <p className="p2 ">
                        You get many extra benefits when directly through the
                        BluO Stays website -:
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">No Hidden Charges or Fees</p>
                      <p className="p2 ">
                        Your online booking total amount is displayed clearly
                        when making your booking via our website and includes
                        all taxes and fees with inclusions/exclusions; many
                        travel websites are charging extra hidden fees for
                        online bookings. Not to forget that when booking
                        directly online with us, you get the lowest rate
                        available rates.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">
                        BluO Stays 72-Hours Cancellation Policy
                      </p>
                      <p className="p2 ">
                        When booking our Best Available Rate, our guests receive
                        a full refund if they decide to cancel their reservation
                        more than 72 hours prior to the arrival date, other
                        travel websites may charge for cancellations.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">Pay-On-Arrival Facility</p>
                      <p className="p2 ">
                        Our guests who book directly, get an option to
                        Pay-On-Arrival when they book our Best Available Rate,
                        it allows them to make full payment on arrival at their
                        apartment.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">
                        Detailed Property Information & Professional Support
                        Team
                      </p>
                      <p className="p2 ">
                        Our website has the most detailed and updated
                        information about our properties including photos,
                        descriptions, pricing, videos, etc. Our experts can
                        assist with finding your perfect apartment and guide you
                        through the booking process.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">
                        Access to Exclusive Offers for our Guests
                      </p>
                      <p className="p2 ">
                        All our guests who have booked directly with us, receive
                        exclusive repeat guest deals and special offers from us
                        via email. They also get other benefits like
                        Complimentary Upgrades, Extras & Extended
                        Check-In/Check-Out timings among others.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">
                        Exceptional Flexibility for Emergencies
                      </p>
                      <p className="p2 ">
                        We understand that our guests can have times of
                        unforeseen emergencies. For all such direct bookings
                        where our guests have a Non-Refundable Cancellation
                        Policy, we can provide exceptions on a case-to-case
                        basis where we offer BluO credit in lieu of the regular
                        Non-Refundable cancellation policy.{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
              {process.env.REACT_APP_LISTINGS === "multi" && (
                <>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">Our Lowest Price Guarantee</p>
                      <p className="p2 ">
                        Book an apartment directly from our Olive website with
                        full confidence that you received the lowest possible
                        rate for your property. If within 24 hours of making
                        your booking on our website, you find a lower qualified
                        pricing for the same apartment, we'll match the rate and
                        give you an extra 25% discount and/or complimentary
                        upgrade! A lower qualified pricing is defined as that of
                        the same accommodation and terms as your current
                        booking, available on any websites besides our own
                        channels, and without restrictions so that it is
                        viewable and bookable by anyone. Just Email us your
                        claim to{" "}
                        <a
                          className="static-links"
                          href="mailto:sales@oliveservicedapartments.com"
                        >
                          sales@oliveservicedapartments.com
                        </a>{" "}
                        so that we can check it and respond back with a
                        resolution.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">Why Book Directly with Olive?</p>
                      <p className="p2 ">
                        You receive many extra benefits when booking directly
                        through the Olive website -
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">No Hidden Charges or Fees</p>
                      <p className="p2 ">
                        When you make your booking online via our website, your
                        total amount clearly displays all fees and taxes plus
                        inclusions/exclusions; some travel portals charge extra
                        hidden fees for bookings via their websites. Besides you
                        are always getting the lowest rates when booking online
                        directly with us.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">72-Hour Cancellation Policy</p>
                      <p className="p2 ">
                        Book our Best Available Rate and get a full refund if
                        you decide to cancel your reservation more than 72 hours
                        prior to your arrival date. Many other travel sites may
                        charge you for cancellations.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">Book Now & Pay-On-Arrival</p>
                      <p className="p2 ">
                        All direct bookings can choose to Pay-On-Arrival when
                        booking our Best Available Rate and make their full
                        payment at the time of check-in to their apartment.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">
                        Repeat Guest Discounts & Loyalty Benefits
                      </p>
                      <p className="p2 ">
                        All our direct booking guests receive periodic Repeat
                        Guest Discount offers besides loyalty benefits like
                        Complimentary Upgrades & Extended Check-In/Check-Out
                        timings, etc.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="static-page-card">
                      <p className="fw-700">Flexibility in case of Emergency</p>
                      <p className="p2 ">
                        For all direct bookings where our guests face any
                        unforeseen emergency that results in changed travel
                        plans, we can arrange exceptions on case-to-case
                        scenarios, and offer Olive credit in lieu of our
                        Non-Refundable cancellation policy.
                      </p>
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Lowprice;
