import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./loginpage.css";
import history from "../../lib/history";
import axios from "../../lib/axios/axios";
import { Alert } from "antd";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import {
  Form,
  Input,
  TextArea,
  Button,
  Select,
  Checkbox,
} from "semantic-ui-react";
import swal from "sweetalert";

// import Form from "react-bootstrap/Form";

// import { Route, withRouter } from "react-dom";

class ForgetPasswordPage extends Component {
  constructor(props, context) {
    super(props);
    let loggedIn = false;
    if (localStorage.getItem("login")) {
      loggedIn = true;
    }
    this.state = {
      loggedIn,
      email: "",
      showSuccess: false,
    };
  }
  componentDidMount() {
    // axios.get(`/be/products/getAll.json`).then(res => {
    //     console.log('result', res.data)
    // })
  }

  forgetPassword = () => {
    const { email } = this.state;
    console.log(email);
    if (email == "") return;

    axios({
      method: "POST",
      url:
        "https://www.oliveservicedapartments.com/olive_api/public/api/validate_user",

      data: {
        email: email,
      },
    }).then((response) => {
      console.log("RESPONSE", response);
      if (response && response.status == 400) {
        return;
      }
      if (response.status == 200) {
        swal(`Please check mail`, {
          icon: "success",
        });

        // this.setState({ showSuccess: true });
        // history.push(
        //   `/olive/auth/reset-password/eyJpdiI6InFSdUVuVUhrTE5DQUs2akU1elJrMHc9PSIsInZhbHVlIjoiQXB4YVBGbUxEOVpLM1luZXNsaUpodz09IiwibWFjIjoiNzk5ZDhkMDNlZTMxYTA5NzYxMDNjMWNhZjAyZDhhMGI0ZmZjMzBiZThkNWIyMTc1YjhkNWM1NTZlM2I4ZjIwNSJ9`
        // );
      }
    });
  };

  render() {
    if (this.state.loggedIn === true && this.props.lgnstatus === true) {
      history.push(
        `/${localStorage.getItem("cityName")}/${localStorage
          .getItem("apartmenttitle")
          .toLowerCase()}/checkout`
      );
    }

    if (this.state.loggedIn === true) {
      return <Redirect to="/" />;
    }
    return (
      <React.Fragment>
        {/* login */}
        {this.state.showSuccess ? (
          <Alert
            message="Please,check mail"
            type="success"
            closable
            onClose={() => this.setState({ showSuccess: false })}
          />
        ) : null}
        <section className="pad-100 bg-grey login-section">
          <div className="container ">
            <div className="offset-md-3 col-md-6 col-12 login-container">
              <React.Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <p className="login-title">
                      Forget Password
                      <div className="hr-line-left-thick"></div>
                      {this.state.msgshow}
                    </p>
                  </div>
                </div>

                <Form className="registerform">
                  <div className="row ">
                    <div className="col-md-12">
                      <Form.Field>
                        <label>Email Address*</label>
                        <input
                          type="email"
                          placeholder="Email"
                          name="username"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                          }}
                          required
                        />
                      </Form.Field>
                    </div>

                    <div className="col-md-6">
                      <Button
                        type="button"
                        className="listing-btn mb-15"
                        onClick={() => this.forgetPassword()}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                </Form>
              </React.Fragment>
            </div>
          </div>
        </section>

        {/* login end */}
      </React.Fragment>
    );
  }
}
export default ForgetPasswordPage;
