import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./staticpages.css";
import { Helmet } from "react-helmet";
import Saving from "../../assets/listingcard/Saving.png";
import Discount from "../../assets/listingcard/Discount.png";
import axios from "../../lib/axios/axios";

import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);

class Blog extends Component {
  state = {
    response: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    axios({
      method: "get",
      url: `https://www.oliveservicedapartments.com/olive_api/public/api/blogs`,
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ response: response.data });
      }
    });
  }
  render() {
    const { response } = this.state;
    const renderHTML = (escapedHTML) =>
      React.createElement("p", {
        dangerouslySetInnerHTML: {
          __html: DOMPurify.sanitize(escapedHTML),
        },
        className: "p2",
      });
    return (
      <React.Fragment>
        <Helmet>
          <title>Blogs</title>
        </Helmet>
        <section className="pad-45">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="listing-card ">
                  <h1 class="font-green mb-30 font-cap text-center">Blogs</h1>
                  {response &&
                    response.length > 0 &&
                    response.map((res, index) => (
                      <>
                        <p className="p2 fw-700">{res.title}</p>
                        {renderHTML(res.content)}
                      </>
                    ))}
                  {/* <p className="p2 fw-700">Why Serviced Apartments better than Hotels?</p>
                            <p className="p2 ">
                                One can have a long discussion over why serviced apartments are better than 
                            hotels. Since the arrival of serviced apartments in the market, many hoteliers feel insecure as 
                            an increasing number of people have started choosing the former accommodation facility. There are 
                            many good reasons behind choosing the new kind of rented accommodation. Many business travellers, 
                            leisure tourists and medical tourists confess to prefer serviced apartments than any hotel for
                             personalised services. Recently, I also chose a posh apartment during my stay in Delhi and my 
                             experience was just awesome!
                             </p>
                             <p className="p2 ">
                             I received warm welcome drink and complementary breakfast which was further accompanied by personal 
                             house-keeping staff, personal kitchenette, Wi-Fi, digital TV connection and personal concierge. 
                             I felt like home while staying in the Olive Apartments in Delhi. Unlike hotels, one can enjoy 
                             personalised services and personal time without any disturbance.
                             </p>
                             <p className="p2 ">
                             So what exactly make serviced apartments better than hotels? Why are they preferred more than hotels? 
                             Let’s find out below.
                             </p>
                             <p className="p2 fw-700">You can live like a king :</p> 
                             <p className="p2 ">
                             Serviced apartments make you to feel like a king with some of the best and personal services 
                             like round the clock concierge, personal house-keeping staff, king-size bed, residential type apartment, 
                             personal terrace, personal gym & spa and many more personalised services unlike hotels.
                             </p>
                             <p className="p2 fw-700">You can feel like home :</p> 
                             <p className="p2 ">
                             This is the best part to choose a serviced apartment over hotels. With personal kitchenette, 
                             comfortable bedrooms and spacious living room, one can feel like home and enjoy all the comforts of home.
                             </p>
                             <p className="p2 fw-700">Nothing short of a temporary housing :</p> 
                             <p className="p2 ">
                             Serviced apartments are also considered as temporary housing stay. Sometimes, relocating to a 
                             new place becomes an issue due to several reasons. With a good serviced apartment, you can beat all the 
                             shifting troubles and easily take care of work, family and leisure without any problem.
                             </p>
                             <p className="p2 fw-700">You can save a lot of avoidable expenses :</p> 
                             <p className="p2 ">
                             SeDuring a stay in some other city brings a good number of miscellaneous expenses such as laundry, 
                             internet, dinner, lunch and breakfast. But you can save all such expenses in rented apartments as they 
                             offer personal laundry service and kitchenette.
                             </p>
                             <p className="p2 fw-700">More privacy & more space :</p> 
                             <p className="p2 ">
                             Many a times we book a hotel but don’t get much privacy and space even after burning a hole in the pocket. 
                             But at serviced apartments one can get both in ample amounts. These apartments come up with cupboards, large 
                             bedrooms, spacious living rooms, sitting equipped terraces, personal kitchenette and attached bathrooms to add 
                             more comfort and style to your stay.
                             </p>
                             <p className="p2 fw-700">Uninterrupted Wi-Fi :</p> 
                             <p className="p2 ">
                             So-called free Wi-Fi service offered in hotels is highly slow and unavailable most of the times. 
                             Either you have to stick to the terrace or reception area in order to receive the network connection. 
                             Fortunately, its not the case with private apartments. Here you can enjoy uninterrupted Wi-Fi right from the 
                             comfort of your bedroom round the clock.
                             </p>
                             <p className="p2 fw-700">Unmatched Convenience & Comfort :</p> 
                             <p className="p2 ">
                             The best part of serviced apartments is that they offer both these features in ample amounts. 
                             If you feel hungry in the middle of the night, you can cook in the kitchen; you can do your laundry at your 
                             convenience and invite friends without making much effort.
                             </p>
                             <p className="p2 ">
                             Last but certainly not the least, serviced apartments act as a boon for all types of tourists including 
                             medical tourists, leisure tourists and business tourists. One doesn’t feel like staying in a hotel. In short, 
                             it’s a home away from home delivering all personalised services at your convenience.
                             </p> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="fl-100 add-card">
                  <img
                    className="d-block w-100"
                    src={Saving}
                    alt="First slide"
                  />
                  <div className="add-text">
                    <p className="fw-700 font-white add-title">
                      Bigger space, bigger saving
                    </p>
                    <p className="add-desc font-white">
                      Book a 2 or 3 Bedroom Apartment &amp; Get 15% Weekly &amp;
                      30% Monthly discount.
                    </p>
                  </div>
                </div>
                <div className="fl-100 add-card">
                  <img
                    className="d-block w-100"
                    src={Discount}
                    alt="First slide"
                  />
                  <div className="add-text">
                    <p className="fw-700 font-white add-title">
                      Stay More, Save More!
                    </p>
                    <p className="add-desc font-white">
                      Book a studio or 1 Bedroom Apartment &amp; Get 10% Weekly
                      &amp; 20% Monthly discount.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Blog;
