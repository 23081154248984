import React from 'react';
import Header from './component/header/header';
import Footer from './component/footer/footer';
import Mobmenu from './component/mobmenu/mobmenu';

import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'circular-std';
import MetaTags from "react-meta-tags";
import {Helmet} from "react-helmet";


if (process.env.REACT_APP_LISTINGS === 'single') {
    import('./Blue.css').then(()=> {})
} else {
    import('./App.css').then(() => {})
}
 
const zohoChat = () => {
  console.log("in bluo ====================?>>>>>>>>>>>>>>>")
  var $zoho=$zoho || {};
      $zoho.salesiq = $zoho.salesiq || {widgetcode: "9b715a70e851105a074c0519e760ec3ea7515c4ee4d0db2e22905126154da17116dd4fa5446ced601c90b4f14018801a", values:{},ready:function(){}};
      var d=document;
      var s=d.createElement("script");
      s.type="text/javascript";
      s.id="zsiqscript";
      s.defer=true;
      s.src="https://salesiq.zoho.com/widget";
      var t=d.getElementsByTagName("script")[0];
      t.parentNode.insertBefore(s,t);
}


class App extends React.Component {
  componentWillMount(){
    if (process.env.REACT_APP_LISTINGS === 'single'){
      window.smartsupp('chat:hide');
      document.getElementById('favicon').href = "favicon.png";
    }
  }

  
  
  render() {
    return (
      <React.Fragment>
        {process.env.REACT_APP_LISTINGS === "single" && (
          <Helmet>
          <script type="text/javascript" id="zsiqchat">
          {zohoChat()}
          </script>
          </Helmet>
        )}
        <MetaTags>
          {/* {process.env.REACT_APP_LISTINGS === "multi" && (
            <> */}
              <title>
                Serviced Apartments: Delhi Gurgaon Hyderabad Bangalore Noida Goa
                Kolkata
              </title>
              <meta
                name="description"
                content="Award Winning Serviced Apartments across India - Delhi, Gurgaon, Hyderabad, Bangalore, Jaipur, Noida, Kolkata, Goa - Corporate Housing Vacation Rentals"
              />
              {/* <meta
                property="og:url"
                content="https://www.oliveservicedapartments.com"
              />
              <meta property="og:type" content="Serviced Apartments" />
              <meta
                property="og:title"
                content="Serviced Apartments: Delhi Gurgaon Hyderabad Bangalore Noida Goa Kolkata"
              />
              <meta
                property="og:description"
                content="OLIVE - Award Winning Serviced Apartments across India - Delhi, Gurgaon, Hyderabad, Bangalore, Jaipur, Noida, Kolkata, Goa - Corporate Housing Vacation Rentals"
              />
              <meta
                property="og:image:url"
                content="https://www.oliveservicedapartments.com/static/media/Banner_1.7ee4f2ce.jpg"
              /> */}
            {/* </>
          )} */}
          {/* {process.env.REACT_APP_LISTINGS === "single" && (
            <>
              
              <meta
                property="og:title"
                content="BluO ShortTerm Rentals Delhi Gurgaon Hyderabad Bangalore KolkataGoa"/>
                <meta
                property="og:description"
                content="BluO Stays - ShortTerm Rentals, Serviced Apartments, Vacation Homes India - Delhi, Gurgaon, Hyderabad, Bangalore, Kolkata, Goa, Jaipur, Noida, Pune, Mumbai"
                />
              <meta
                property="og:url"
                content="https://staybluo.com/"
              />
            </>
          )} */}
          {/* <title>
            Serviced Apartments: Delhi Gurgaon Hyderabad Bangalore Noida Goa
            Kolkata
          </title>
          <meta
            name="description"
            content="OLIVE - Award Winning Serviced Apartments across India - Delhi, Gurgaon, Hyderabad, Bangalore, Jaipur, Noida, Kolkata, Goa - Corporate Housing Vacation Rentals"
          /> */}
          {/* <meta
            property="og:url"
            content="https://www.oliveservicedapartments.com"
          />
          <meta property="og:type" content="Serviced Apartments" />
          <meta
            property="og:title"
            content="Serviced Apartments: Delhi Gurgaon Hyderabad Bangalore Noida Goa Kolkata"
          />
          <meta
            property="og:description"
            content="OLIVE - Award Winning Serviced Apartments across India - Delhi, Gurgaon, Hyderabad, Bangalore, Jaipur, Noida, Kolkata, Goa - Corporate Housing Vacation Rentals"
          />
          <meta
            property="og:image:url"
            content="https://www.oliveservicedapartments.com/static/media/Banner_1.7ee4f2ce.jpg"
          /> */}
        </MetaTags>
        <Header />
        <Footer />
        <div className="fl-100 empty-55 mobview"></div>
        <Mobmenu />
      </React.Fragment>

    );

  }
}


export default App;