import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./staticpages.css";
import { Helmet } from "react-helmet";
import Saving from "../../assets/listingcard/Saving.png";
import Discount from "../../assets/listingcard/Discount.png";
import axios from "../../lib/axios/axios";

import createDOMPurify from "dompurify";

const DOMPurify = createDOMPurify(window);
class Privacypolicy extends Component {
  state = {
    response: [],
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.handleAmenityDetail();
    // axios.get(`/be/products/getAll.json`).then(res => {
    //     console.log('result', res.data)
    axios({
      method: "get",
      url: `https://www.oliveservicedapartments.com/olive_api/public/api/privacy_policy`,
    }).then((response) => {
      if (response.status === 200) {
        this.setState({ response: response.data });
      }
    });
  }

  render() {
    const { response } = this.state;
    const renderHTML = (escapedHTML) =>
      React.createElement("p", {
        dangerouslySetInnerHTML: {
          __html: DOMPurify.sanitize(escapedHTML),
        },
        className: "p2",
      });
    return (
      <React.Fragment>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>
        <section className="pad-45">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="listing-card ">
                  <h1 class="font-green mb-30 font-cap text-center">
                    Privacy Policy
                  </h1>
                  <p className="p2 ">
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}
                    (Blue Leaf Corporate Solutions P Ltd.) understands that
                    guest care about the use and storage of their personal
                    information and data and we value your trust in allowing us
                    to do this in a careful and sensible manner.
                  </p>
                  <p className="p2 ">
                    We’ve created this privacy policy statement in order to
                    demonstrate our commitment to the privacy of our guest. By
                    using the{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}
                    , our website, our dedicated telephone reservations line and
                    any other
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    service, you are consenting and agreeing to the practices
                    outlined in this statement.
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    will always handle information in compliance with the Data
                    Protection Act (1998).
                  </p>
                  {response &&
                    response.length > 0 &&
                    response.map((res, index) => (
                      <>
                        <p className="fw-700">{res.title}</p>
                        {renderHTML(res.content)}
                      </>
                    ))}
                  {/* <p className="p2 fw-700">
                    Collection of data and use of your personal information
                  </p>
                  <p className="p2 ">
                    We collect your data and personal information in the
                    following ways:
                    <br />
                    * On our website when you make or manage a booking; register
                    or alter your details; use the ‘contact us’ email addresses.
                    <br />
                    * In our Customer Services department from your
                    correspondence.
                    <br />
                    * By telephone when speaking to a call center agent or
                    directly with us.
                    <br />* In our properties when you register at front desk.
                  </p>
                  <p className="p2 fw-700">
                    We use your data and personal information in the following
                    ways:
                  </p>
                  <p className="p2 ">
                    * To continually improve your customer experience. We use
                    the information to process bookings and payments,
                    communicate with you about bookings, products, services and
                    promotional offers, update our records, create and maintain
                    your{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}
                    (s) and recommend products and services that might be of
                    interest to you. We may also contact you to ask about your
                    experience using our hotels and services as part of a
                    continual programme of customer service improvement.
                  </p>
                  <p className="p2 ">
                    * To improve our website, prevent or detect fraud or abuses
                    of our website and enable third parties to carry out
                    technical, logistical or other functions on our behalf.
                  </p>
                  <p className="p2 ">
                    * To provide you with requested information or
                    correspondence, such as a response from us to an enquiry
                    made by you.
                  </p>
                  <p className="p2 ">
                    * To send you details of your new, amended or cancelled
                    booking or the{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    account details.
                  </p>
                  <p className="p2 ">
                    * To contact you in the event of a change that affects your
                    booking or any data or personal information you have
                    provided us with, such as changes to terms and conditions of
                    booking or this privacy policy.
                  </p>
                  <p className="p2 ">
                    With your permission, your contact details may be used to
                    supply information to you by telephone, email or post, about
                    our company. And to send you occasional promotional
                    material, such as information about special offers, which we
                    think you, might find valuable. If you do not agree to
                    receive this information, you may opt out from receiving
                    future information at any time; see the opt-out section of
                    this policy.
                  </p>
                  <p className="p2 ">
                    * We may also provide aggregate statistics about our guest,
                    sales, traffic patterns and related site information to
                    reputable third parties but these statistics will include no
                    personally identifying information.
                  </p>
                  <p className="p2 ">
                    * We might receive information about you from other sources
                    and add it to our account information. This ensures that the
                    quality of data we have on your account(s) is maintained
                    properly.
                  </p>
                  <p className="p2 fw-700">Non-disclosure to third parties</p>
                  <p className="p2">
                    The information and data we collect is important for the{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    and we would not want to share this anyone else. Unless we
                    have your express consent, we will never disclose, rent,
                    trade or sell your personal data to any third parties for
                    their marketing or mailing purposes.
                  </p>
                  <p className="p2">
                    We do disclose or transfer your data or personal information
                    to other companies, data processors or agents employed by us
                    to perform any necessary functions on our behalf, but they
                    are bound by our privacy policy and may not use this
                    information for their own purposes.
                  </p>
                  <p className="p2">
                    In the event that{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    or any part of its business is sold to or integrated with
                    another business,{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    may disclose your personal information to the new owners
                    (and their professional advisers on the transaction) to be
                    used by the new owners and their group of companies in the
                    same ways as set out in this Privacy Policy, including to
                    continue providing you with the same services and marketing
                    information services as are currently provided by{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}
                    .
                  </p>
                  <p className="p2">
                    Credit or debit card data is never viewed or disclosed to
                    anyone, ever.
                  </p>
                  <p className="p2 fw-700">Cookies</p>
                  <p className="p2">
                    Our site uses cookies to keep track of your visits to our
                    website. A cookie is a small file that can be stored by your
                    browser on your computer’s hard drive. Cookies may be used
                    to compile anonymous statistics related to the use of
                    services or patterns of browsing. When used in this manner
                    you are not individually identified and data collected in
                    this manner is only used in aggregate.
                  </p>
                  <p className="p2">
                    You can usually change your browser’s settings so that it
                    will not accept cookies, although this may restrict some
                    website functionality.
                  </p>
                  <p className="p2">
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    will only use cookies with your permission.
                  </p>
                  <p className="p2 fw-700">Security of your information</p>
                  <p className="p2">
                    We follow strict security procedures in the storage and
                    disclosure of information, which you have given us. This is
                    to prevent unauthorized access or unlawful processing of
                    your personal information. For additional peace of mind,
                    credit card details are not recorded on{" "}
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " Olive Serviced Apartments "
                      : " BluO Stays "}{" "}
                    site when booking a room.
                  </p>
                  <p className="p2 fw-700">Your consent</p>
                  <p className="p2">
                    By using our website you consent to the collection, storage
                    and processing of your personal information by us in the
                    manner set out in this privacy policy. Should we change our
                    privacy policy, we will post the changes on this website so
                    that you are always aware of what information we collect,
                    how we use and under what circumstances we disclose it.
                  </p>
                  <p className="p2 fw-700">Opt out / Data quality / Access</p>
                  <p className="p2">
                    We will always ask your permission before sending you
                    electronic marketing information. This ensures you only
                    receive information that you have given us permission to
                    send and are willing to receive.
                  </p>
                  <p className="p2">
                    It is possible, if you have given us your permission to
                    receive a type of electronic marketing communication, to
                    alter your contact preferences at any time. You can do this
                    by:
                  </p>
                  <p className="p2">
                    * Sending an email to
                    {process.env.REACT_APP_LISTINGS === "multi"
                      ? " info@oliveservices.com "
                      : " info@staybluo.com "}{" "}
                    with your name, address and if you have made a booking with
                    us a confirmation number.
                  </p>
                  <p className="p2">
                    * On promotional emails we always provide a link at the
                    bottom of the email to unsubscribe you from that service.
                  </p>
                  <p className="p2">
                    Please note it is not possible to ‘opt-out’ of receiving
                    communication from us, which relate to your bookings. This
                    ensures we can always contact you in the event of a
                    circumstance that affects your stay with us.
                  </p>
                  <p className="p2">
                    We might receive information about you from other sources,
                    such as the Mail Preference Service, and add it to our
                    account information. This ensures that the quality of data
                    we have on your account(s) is maintained properly.
                  </p>
                  <p className="p2">
                    If you wish to view, delete or amend any of the information
                    we hold on you, contacting us in writing at the address
                    provided above can do this. We will not charge for amending
                    or deleting personal information, but may charge for viewing
                    information held on you as specified in the Data Protection
                    Act (1998).
                  </p> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="fl-100 add-card">
                  <img
                    className="d-block w-100"
                    src={Saving}
                    alt="First slide"
                  />
                  <div className="add-text">
                    <p className="fw-700 font-white add-title">
                      Bigger space, bigger saving
                    </p>
                    <p className="add-desc font-white">
                      Book a 2 or 3 Bedroom Apartment &amp; Get 15% Weekly &amp;
                      30% Monthly discount.
                    </p>
                  </div>
                </div>
                <div className="fl-100 add-card">
                  <img
                    className="d-block w-100"
                    src={Discount}
                    alt="First slide"
                  />
                  <div className="add-text">
                    <p className="fw-700 font-white add-title">
                      Stay More, Save More!
                    </p>
                    <p className="add-desc font-white">
                      Book a studio or 1 Bedroom Apartment &amp; Get 10% Weekly
                      &amp; 20% Monthly discount.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Privacypolicy;
